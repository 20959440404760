 <template>
	<el-dialog title="添加网点用户" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-position="left" label-width="75px">
			
			<el-form-item label="用户姓名">
				<el-input v-model="form.name" placeholder="请输入用户姓名" clearable></el-input>
			</el-form-item>

			<el-form-item label="用户电话">
				<el-input v-model="form.tel" type="number" placeholder="请输入用户电话" clearable></el-input>
			</el-form-item>

			<el-form-item label="用户类型">
				<el-select v-model="form.type" style="width: 100%;" clearable>
					<el-option label="网点负责人" value="1"></el-option>
					<el-option label="网点调度" value="2"></el-option>
					<el-option label="其他员工" value="3"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="网点信息">
				<el-select v-model="form.outlets_num" style="width: 100%;" placeholder="请选择用户归属网点" clearable>
					<el-option
						v-for="(item,index) in outlets_list"
						:key="index"
						:label="item.name+'('+item.prov+'/'+item.city+'/'+item.county+' / '+item.addr+')'"
						:value="item.outlets_num"
					></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="备注">
				<el-input v-model="form.mark" placeholder="请输入用户备注" clearable></el-input>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import {regionDataPlus,CodeToText} from 'element-china-area-data'
	export default {
		props:{
			is_show:Number,
			outlets_list:Array,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//省市县基础数据
				cities:regionDataPlus,

				//表单
				form: {

					//用户姓名
					name:'',

					//用户电话
					tel:'',

					//用户类型
					type:"",

					//网点信息
					outlets_num:"",

					//备注
					mark:''
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {
			
			//提交
			sub(){

				//用户姓名
				if(!this.form.name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"请输入用户姓名"
					});
					return;
				}

				//用户电话
				if(!this.$my.check.is_tel(this.form.tel)){
					this.$my.other.msg({
						type:'warning',
						str:"请输入用户手机"
					});
					return;
				}

				//类型(1:网点负责人,2:网点调度,3:其他)
				if(['1','2','3'].indexOf(this.form.type)==-1){
					this.$my.other.msg({
						type:'warning',
						str:"请选择用户类型"
					});
					return;
				}

				//网点信息
				if(!this.form.outlets_num.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"网点编号为空,请选择网点信息"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'user_add_admin',
						outlets_num:this.form.outlets_num,
						type:this.form.type,
						name:this.form.name.trim(),
						tel:this.form.tel,
						mark:this.form.mark,
					},
					callback:(data)=>{

						//通知
						this.$emit("sub");
					}
				});
			},
		}
	}
</script>